<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol lg="5">
          <CAlert
              :color="getColor"
              closeButton
              :show.sync="dismissCountDown"
              fade
          >
            {{ msg }}
          </CAlert>
          <CCardGroup>
            <CCard class="p-3">
              <CCardBody>
                <CForm @submit.prevent="onSubmit">
                  <h1>Reset Password</h1>
                  <p class="text-muted">
                    Enter your email to get a password reset link
                  </p>
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                        ><CIcon name="cil-user"
                        /></span>
                      </div>
                      <input
                          :maxlength="maxlength.email"
                          type="email"
                          class="form-control"
                          autocomplete="email"
                          v-model="forgot.email"
                          placeholder="Email"
                      />
                    </div>
                    <small
                        class="error"
                        v-if="$v.forgot.$error && !$v.forgot.email.required"
                    >Email is required</small
                    >
                    <small
                        class="error"
                        v-if="$v.forgot.$error && !$v.forgot.email.email"
                    >Email is not valid</small
                    >
                  </div>
                  <CRow>
                    <CCol col="10" class="text-left">
                      <CButton color="primary" class="px-4" type="submit"
                      >Sent Password Reset Link
                      </CButton
                      >
                    </CCol>
                    <CCol col="2" class="text-right">
                      <CButton color="link" class="px-0" @click="loginUrl()"
                      >Login
                      </CButton
                      >
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import Vue from "vue";
import {General} from "/src/store/url.js";
import store from "/src/store/store.js";
import {Mixin} from "/src/mixins/index.js";
import {required, email} from "vuelidate/lib/validators";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";
import {Maxlength} from "/src/store/maxlength";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};

Vue.use(VueNotifications, options);
// Register it globally
export default {
  name: "ForgotPassword",
  mixins: [Mixin],
  components: {},
  data() {
    return {
      color: "",
      getColor: "",
      dismissCountDown: 0,
      msg: "",
      forgot: {
        email: "",
      },
      maxlength: {
        email: Maxlength.forgot.email,
      },
      module: General,
    };
  },
  validations: {
    forgot: {
      email: {
        required,
        email,
      },
    },
  },
  mounted() {
    store.commit("showLoader", false); // Loader Off
    this.dismissCountDown = 0;
  },
  methods: {
    loginUrl() {
      let self = this;
      self.$router.push({path: "/signin"});
    },
    onSubmit() {
      this.$v.forgot.$touch();
      if (this.$v.forgot.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        let self = this;
        store.commit("showLoader", true); // Loader Off
        axios
            .post(this.forgotUrlApi(this.module), self.forgot)
            .then(function (response) {
              if (response.status === 200) {
                store.commit("showLoader", false); // Loader Off
                self.msg = response.data.response.message;
                if (self.msg === "This email address is not found in the system") {
                  self.color = "danger";
                  self.getColor = self.color;
                  self.dismissCountDown = 10;
                  store.commit("showLoader", false); // Loader Off
                } else {
                  store.commit("showLoader", false); // Loader Off
                  self.color = "success";
                  self.getColor = self.color;
                  self.dismissCountDown = 10;
                }
              }
            });
      }
    }
  },
};
</script>
